import {Coin, coinList} from "../config/coin";
import {Reserve, PairMap} from "./pairList";
import _ from "lodash";
import {SwapHost, swapHostList} from "../config/swapHostList";


export type Swap =  {
  coinFrom: Coin,
  coinTo: Coin,
  amountFrom: number,
  amountTo: number,
  swapHost: SwapHost,
  debug?: any,
};

export type CoinNode = {
  coin: Coin,
  amount: number,
  feeAmount: number,
  pairMap: PairMap,
  noUpdateCounter: number,
  swapList: Swap[],
  debug? : any,
}

export type fetchPathProp = {
  coinList: Coin[],
  pairMap: PairMap,
  coinInAddress: string,
  coinInAmount: number,
  coinOutAddress: string,
  multiSwapFee: number,
  maxSwap: number,
  setRounds: any,
}

export const getCoin = (coinAddress: string) => {
  for (const coin of coinList.testnet) {
    if (coinAddress === coin.address){
      return coin
    }
  }
  throw new Error('wrong coinAddress')
}

const getCoinOutAmount = (amountIn: number, reserveIn: number, reserveOut: number, fee: number): number => {
  if (amountIn <= 0) return -1
  if (reserveIn <= 0) return -1
  if (reserveOut <= 0) return -1
  const amountInWithFee = Math.floor(amountIn * (1 - fee))
  const numerator = Math.floor(amountInWithFee * reserveOut)
  const denominator = Math.floor(reserveIn + amountInWithFee)
  const amountOut = Math.floor(numerator / denominator)
  if (amountOut > reserveOut) return -1
  return amountOut
}
function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const fetchPath = async ({coinList, pairMap, coinInAddress, coinInAmount, coinOutAddress, multiSwapFee, maxSwap, setRounds}: fetchPathProp) => {
  // find coinIn and coinOut
  const coinIn = getCoin(coinInAddress)
  // const coinOut = getCoin(coinOutAddress)

  coinInAmount = Math.floor(coinInAmount * Math.pow(10, coinIn.decimals))
  const feeAmount =  Math.floor(coinInAmount * multiSwapFee)
  // take fee out
  let coinInAmountWithMainFee = coinInAmount - feeAmount

  const rounds: CoinNode[][] = [[]]
  let coinInId: undefined | number
  let coinOutId: undefined | number
  for (let i = 0; i < coinList.length; i++) {
    if (coinList[i].address === coinInAddress) {
      coinInId = i
    }
    if (coinList[i].address === coinOutAddress) {
      coinOutId = i
    }
  }
  if (coinInId === undefined) throw new Error('wrong coinInAddress')
  if (coinOutId === undefined) throw new Error('wrong coinOutAddress')

  // init nodes
  for (let i = 0; i < coinList.length; i++) {
    if (i === coinInId) {
      rounds[0][i] = {
        coin: coinList[i],
        amount: coinInAmountWithMainFee,
        feeAmount: feeAmount,
        pairMap: _.cloneDeep(pairMap),
        swapList: [],
        noUpdateCounter: 0,
      }
    } else {
      rounds[0][i] = {
        coin: coinList[i],
        amount: 0,
        feeAmount: 0,
        pairMap: _.cloneDeep(pairMap),
        swapList: [],
        noUpdateCounter: 0,
      }
    }
  }

  let r = 1
  while (true) {
    rounds[r] = _.cloneDeep(rounds[r - 1])
    //set multiSwapFee = 0
    for (let i = 0; i < coinList.length; i++) {
      rounds[r][i].noUpdateCounter++

      // const lastSwap = rounds[r - 1][i].swapList[rounds[r - 1][i].swapList.length - 1]
      rounds[r][i].swapList.push({
        coinFrom: coinList[i],
        coinTo:  coinList[i],
        amountFrom: rounds[r - 1][i].amount,
        amountTo: rounds[r - 1][i].amount,
        swapHost: swapHostList[0],
      })
      rounds[r][i]['debug'] = {
        swapListMeta: {
          swapHost: swapHostList[0]
        }
      }
    }

    for (let i = 0; i < coinList.length; i++) {
      let coinNodeTo = _.cloneDeep(rounds[r][i])

      for (let j = 0; j < coinList.length; j++) {
        if (i === j) continue

        const coinNodeFrom = _.cloneDeep(rounds[r - 1][j])
        // find pair
        const keys = Object.keys(coinNodeFrom.pairMap)
        let balanceIn: "balance0" | "balance1"
        let balanceOut: "balance0" | "balance1"
        let pair
        let pairMapKey
        if (keys.includes(`${coinNodeFrom.coin.address},${coinNodeTo.coin.address}`)) {
          pairMapKey = `${coinNodeFrom.coin.address},${coinNodeTo.coin.address}`
          pair = coinNodeFrom.pairMap[pairMapKey]
          balanceIn = "balance0"
          balanceOut = "balance1"

        } else if (keys.includes(`${coinNodeTo.coin.address},${coinNodeFrom.coin.address}`)) {
          pairMapKey = `${coinNodeTo.coin.address},${coinNodeFrom.coin.address}`
          pair = coinNodeFrom.pairMap[pairMapKey]
          balanceIn = "balance1"
          balanceOut = "balance0"

        } else {
          continue
        }

        // check best path
        // find best reserve of coinNodeFrom
        let bestReserve: undefined | Reserve
        for (const k of Object.keys(pair.reserves)) {
          const reserve0 = pair.reserves[k]
          if (!bestReserve) {
            bestReserve = reserve0
            continue
          }
          const coinOutAmount= getCoinOutAmount(
            coinNodeFrom.amount,
            bestReserve[balanceIn],
            bestReserve[balanceOut],
            bestReserve.swapHost.fee,
          )
          const coinOutAmount0= getCoinOutAmount(
            coinNodeFrom.amount,
            reserve0[balanceIn],
            reserve0[balanceOut],
            reserve0.swapHost.fee,
          )
          if (coinOutAmount < coinOutAmount0) {
            bestReserve = reserve0
          } else if (coinOutAmount === coinOutAmount0) {
            bestReserve = bestReserve[balanceIn] < reserve0[balanceIn] ? reserve0 : bestReserve
          }
        }
        if (!bestReserve) continue

        // compare coinNodeTo.amount and coinOutAmount
        const coinNodeFromCoinOutAmount = getCoinOutAmount(
          coinNodeFrom.amount,
          bestReserve[balanceIn],
          bestReserve[balanceOut],
          bestReserve.swapHost.fee,
        )

        let isUpdated = false
        if (coinNodeTo.amount < coinNodeFromCoinOutAmount) {
          if (coinNodeFrom.amount <= bestReserve[balanceIn])
          isUpdated = true
        }

        if (isUpdated) {
          rounds[r][i] = _.cloneDeep(coinNodeFrom)
          rounds[r][i].coin = coinNodeTo.coin
          rounds[r][i].amount = coinNodeFromCoinOutAmount
          rounds[r][i].feeAmount = 0
          rounds[r][i].noUpdateCounter = 0

          rounds[r][i].swapList.push({
            coinFrom: coinNodeFrom.coin,
            coinTo: coinNodeTo.coin,
            amountFrom: coinNodeFrom.amount,
            amountTo: coinNodeFromCoinOutAmount,
            swapHost: bestReserve.swapHost,
          })

          const reserve = rounds[r][i].pairMap[pairMapKey].reserves[bestReserve.swapHost.id]

          rounds[r][i]['debug'] = {
            pairMapKey,
            pairMapReserve: pairMap[pairMapKey].reserves[bestReserve.swapHost.id],
            balanceIn,
            balanceOut,
            swapHostName: bestReserve.swapHost.name,
            reserveBalanceInBefore: reserve[balanceIn],
            reserveBalanceOutBefore: reserve[balanceOut],
            reserveBalance0Before: reserve.balance0,
            reserveBalance1Before: reserve.balance1,
          }
          // update reserve
          reserve[balanceIn] += coinNodeFrom.amount;
          reserve[balanceOut] -= coinNodeFromCoinOutAmount

          rounds[r][i].debug.reserveBalanceInAfter = reserve[balanceIn]
          rounds[r][i].debug.reserveBalanceOutAfter = reserve[balanceOut]
          rounds[r][i].debug.reserveBalance0After = reserve.balance0
          rounds[r][i].debug.reserveBalance1After = reserve.balance1

          // update coinNodeTo
          coinNodeTo = rounds[r][i]
        }
      }
    }
    if (r % 5 === 0) {
      setRounds([...rounds])
      await sleep(0)
    }
    // end conditions
    // max round
    if (r === maxSwap) break

    // noUpdateCounter of each node is greater than 0
    let isUpdated = false
    for (let i=0; i < coinList.length; i++) {
      if (rounds[r][i].noUpdateCounter === 0) {
        isUpdated = true
        break
      }
    }
    if (!isUpdated) break

    // coinOut waits long enough
    if (rounds[r][coinOutId].noUpdateCounter >= (coinList.length - 1) * 3) {
      break
    }

    // noPath
    if (r === coinList.length - 1 && rounds[r][coinOutId].noUpdateCounter >= coinList.length - 1) break

    r++
  }
  setRounds([...rounds])

  const coinOutNode = rounds[r][coinOutId]
  return {coinOutNode, rounds}
}