import { Provider, Network } from "aptos";
import { Coin, coinPairList } from "../config/coin";
import { swapHostList, SwapHost } from "../config/swapHostList";

const provider = new Provider(Network.TESTNET)

export type Reserve = {
  balance0: number,
  balance1: number,
  swapHost: SwapHost,
}

export type Pair = {
  coin0: Coin,
  coin1: Coin,
  reserves: {
    [hostId: string]: Reserve
  },
}

export type PairMap = {
  [pairKey: string]: Pair
}

export const fetchPairMap = async (): Promise<PairMap> => {
  const pairMap: PairMap = {}

  for (const swapHost of swapHostList) {
    const swapHostPairs = await getSwapHostPairs(swapHost)
    // merge pairs and swapHostPairs
    for (const swapHostPair of swapHostPairs) {
      const key = `${swapHostPair.coin0.address},${swapHostPair.coin1.address}`
      if (!Object.keys(pairMap).includes(key)) {
        pairMap[key] = swapHostPair
      } else {
        const hostId = Object.keys(swapHostPair.reserves)[0]
        pairMap[key].reserves[hostId] = swapHostPair.reserves[hostId]
      }
    }
  }
  return pairMap
}

const getSwapHostPairs = async (swapHost: SwapHost) => {

  const pairs: Pair[] = []

  const resources = await provider.getAccountResources(swapHost.resourceAccountAddress)
  resources.forEach((r: any) => {
    r.type = r.type.replace(/\s/g, '');
    if (!r.type.includes(swapHost.poolAddress)) return
    for (const coinPair of coinPairList) {
      let balance0
      let balance1
      if (r.type.includes(`${coinPair[0].address},${coinPair[1].address}`)) {
        if (swapHost.type === 'PancakeSwap') {
          balance0 = parseInt((r as any).data[swapHost.var_balance_x])
          balance1 = parseInt((r as any).data[swapHost.var_balance_y])

        } else if (swapHost.type === 'AnimeSwap') {
          balance0 = parseInt((r as any).data[swapHost.var_balance_x].value)
          balance1 = parseInt((r as any).data[swapHost.var_balance_y].value)
        } else {
          throw new Error('no type match')
        }
      } else if (r.type.includes(`${coinPair[1].address}, ${coinPair[0].address}`)) {
        if (swapHost.type === 'PancakeSwap')  {
          balance0 = parseInt((r as any).data[swapHost.var_balance_y])
          balance1 = parseInt((r as any).data[swapHost.var_balance_x])
        } else if (swapHost.type === 'AnimeSwap') {
          balance0 = parseInt((r as any).data[swapHost.var_balance_y].value)
          balance1 = parseInt((r as any).data[swapHost.var_balance_x].value)
        } else {
          throw new Error('no type match')
        }
      } else {
        continue
      }
      pairs.push({
        coin0: coinPair[0],
        coin1: coinPair[1],
        reserves: {
          [swapHost.id]: {
            balance0,
            balance1,
            swapHost,
          }
        }
      })
    }
  })
  return pairs
}
