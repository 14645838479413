import React from 'react';
import {Layout, Row, Col, Button, InputNumber, Select, Switch} from "antd";
import { WalletSelector } from "@aptos-labs/wallet-adapter-ant-design";
import "@aptos-labs/wallet-adapter-ant-design/dist/index.css"
import { Provider, Network } from "aptos"
import { useWallet } from "@aptos-labs/wallet-adapter-react"
import { useState, useEffect } from "react"
import {ReactFlow, Node, Edge} from 'reactflow'
import 'reactflow/dist/style.css';
import { coinList } from "./config/coin";
import { fetchPairMap } from "./lib/pairList";
import {CoinNode, fetchPath} from "./lib/path";
import {multiSwapFee} from "./config"

const provider = new Provider(Network.TESTNET)
const moduleAddress = "0x3b8d05004851f6463029f5c9b815aedfe0a36a8412e2eae0e4d5849812a5c685"


const initPosition = {x: 10, y: 25}
const offsetNormalView = {x:160, y:100}
const offsetDetailedView = {x:160, y:100}

function App() {
  const { account, signAndSubmitTransaction } = useWallet();

  const [coinOptions] = useState(
    coinList.testnet.map(v=> {
      return {value: v.address, label: v.symbol}
    })
  )
  const [coinInAddress, setCoinInAddress] = useState(coinList.testnet[0].address)
  const [coinOutAddress, setCoinOutAddress] = useState(coinList.testnet[1].address)
  const [maxSwap] = useState(40)
  const [swapDisabled, setSwapDisabled] = useState(true)
  const [progressing, setProgressing] = useState(false)
  const [coinInAmount, setCoinInAmount] = useState(0)
  const [coinOutAmount, setCoinOutAmount] = useState(0)
  const [rounds, setRounds] = useState<CoinNode[][]>([])
  const [advancedMode, setAdvancedMode] = useState(false)

  const [nodes, setNodes] = useState<Node[]>([])
  const [edges, setEdges] = useState<Edge[]>([])

  const handleChange0 = (value: string) => {
    setCoinInAddress(value)
    setCoinOutAmount(0)
    setSwapDisabled(true)

  }
  const handleChange1 = (value: string) => {
    setCoinOutAddress(value)
    setCoinOutAmount(0)
    setSwapDisabled(true)
  }
  const handleCoinInAmountChange = (value: number | null) => {
    if (value == null) value = 0
    setCoinInAmount(value)
  }
  const handleDetailSwitchChange = (value: boolean) => {
    setAdvancedMode(value)

  }
  const handleSwap = () => {
    if (!account) return
    if (rounds.length <= 1) return
    setProgressing(true)

    // get
    let typeArgLength = 40
    if (rounds.length <= 10) {
      typeArgLength = 10
    } else if (rounds.length <= 20) {
      typeArgLength = 20
    }

    // type_arguments
    const coinInId = getCoinId(coinInAddress)
    const coinOutId = getCoinId(coinOutAddress)
    const swapList = rounds[rounds.length -1][coinOutId].swapList
    const typeArguments = []
    typeArguments[0] = swapList[0].coinFrom.address
    const swapHosts = []
    for (let i = 1; i <= typeArgLength; i++) {
      if (i <= swapList.length  ) {
        typeArguments.push(swapList[i - 1].coinTo.address)
        if (swapList[i - 1].coinFrom.address !== swapList[i - 1].coinTo.address) {
          swapHosts.push(swapList[i - 1].swapHost.id)
        }
      } else {
        typeArguments.push(typeArguments[typeArguments.length - 1])
      }
    }
    let x_last_min_out
    if (coinInId !== coinOutId) {
      x_last_min_out = Math.floor(rounds[rounds.length - 1][coinOutId].amount * 0.9)
    } else {
      x_last_min_out = Math.floor(rounds[rounds.length - 1][coinOutId].amount + 1)
    }

    const payload = {
      type: "entry_function_payload",
      function: `${moduleAddress}::multiswap::multiswap_exact_input_${typeArgLength}`,
      type_arguments: typeArguments,
      arguments: [
        swapHosts,
        Math.floor(coinInAmount* Math.pow(10, coinList.testnet[coinInId].decimals)),
        x_last_min_out,
      ]
    }
    signAndSubmitTransaction(payload).then((response) => {
      provider.waitForTransaction(response.hash).then(console.log).catch(console.error).finally(()=> setProgressing(false))
    }).catch(console.error)
      .finally(()=> setProgressing(false))

  }

  const handleOptimize = () => {
    if (coinInAmount <= 0) return
    setNodes([])
    setEdges([])
    setProgressing(true)
    fetchPairMap().then((pairMap)=>{
      fetchPath({
        coinList: coinList.testnet,
        pairMap,
        coinInAddress,
        coinInAmount,
        coinOutAddress,
        multiSwapFee,
        maxSwap,
        setRounds,
      }).then(({coinOutNode, rounds})=>{
        setProgressing(false)
        setSwapDisabled(false)
      })
        .catch((err)=>{
          setProgressing(false)
          setSwapDisabled(true)
        })

    }).catch(()=>{
      setProgressing(false)
      setSwapDisabled(true)
    })
  }

  const getNodeLabel = (coinAddress: string) => {
    const token = coinAddress.split('::')
    let shortAddress
    if (token[0].length <= 6) {
      shortAddress = token[0]
    } else {
      shortAddress = token[0].substring(2,6) + '..'
    }

    return `${shortAddress}:${token[2]}`
  }
  const getCoinId = (coinAddress: string) => {
    for (let i = 0; i<coinList.testnet.length; i++) {
      if (coinAddress === coinList.testnet[i].address) return i
    }
    throw new Error('no coinAddress matched')
  }

  useEffect(()=> {
    if (rounds.length === 0) return
    // draw graph
    // nodes
    setNodes([])
    setEdges([])
    const coinOutId = getCoinId(coinOutAddress)
    const nodes: Node[] = []
    const offset = advancedMode ? offsetDetailedView : offsetNormalView

    for (let i=0; i < rounds.length; i++) {
      for (let j = 0; j < rounds[i].length; j++) {
        if (rounds[i][j].amount === 0) continue

        const node: Node = {
          id: `${i},${rounds[i][j].coin.address}`,
          data: {
            label: getNodeLabel(rounds[i][j].coin.address) + `\n(${(rounds[i][j].amount + rounds[i][j].feeAmount) / Math.pow(10, rounds[i][j].coin.decimals)} ${rounds[i][j].coin.symbol})`,
          },
          position: {x: initPosition.x + offset.x * j, y: initPosition.y + offset.y * i},
        }
        if (advancedMode) {
          node.data.label += `\n ${(rounds[i][j].noUpdateCounter)}`
        }
        if (i === 0) {
          node.type = 'input'
        }
        if (i === rounds.length-1) {
          node.type = 'output'
        }
        nodes.push(node)
      }
    }
    setNodes(nodes)

    // edges
    const edges: Edge[] = []

    // optimized path
    setCoinOutAmount(rounds[rounds.length - 1][coinOutId].amount / Math.pow(10, coinList.testnet[coinOutId].decimals))
    const swapList = rounds[rounds.length - 1][coinOutId].swapList
    if (swapList.length === 0) return

    let nodeFromId = `0,${swapList[0].coinFrom.address}`
    for (let r= 0; r < swapList.length; r++) {
      const nodeToId =  `${r+1},${swapList[r].coinTo.address}`
      const edge: Edge = {
        id: `${r},${nodeFromId},${nodeToId}`,
        source: nodeFromId,
        target: nodeToId,
        label:  swapList[r].swapHost.name,
        style: {
          strokeWidth: 2,
          stroke: swapList[r].swapHost.color,
        },
      };
      edges.push(edge)
      nodeFromId = nodeToId
    }
    setEdges(edges)

  }, [rounds, advancedMode, coinOutAddress])

  const selectAfter0 = (
    <Select defaultValue={coinInAddress}
            style={{ width: 120 }}
            onChange={handleChange0}
            options={coinOptions}
            disabled={progressing}
    />
  )

  const selectAfter1 = (
    <Select defaultValue={coinOutAddress}
            style={{ width: 120 }}
            onChange={handleChange1}
            options={coinOptions}
            disabled={progressing}
    />
  )


  return (
    <>
      <Layout style={{ padding: "5px"}}>
        <Row align="middle">
          <Col span={12}>
            <h1>Unified Swap</h1>

          </Col>
          <Col span={12} style={{ textAlign: "right"}}>
            <WalletSelector />
          </Col>
        </Row>
      </Layout>
      <Layout>
        <Row align="middle">
          <Col span={12}>
            <InputNumber
              addonAfter={selectAfter0}
              defaultValue={0}
              onChange={handleCoinInAmountChange}
              value={coinInAmount}
            />
            <InputNumber
              addonAfter={selectAfter1}
              defaultValue={0}
              onChange={()=>{setCoinOutAmount(coinOutAmount)}}
              value={coinOutAmount}
            />
            <Button type="primary" disabled={progressing} onClick={handleOptimize}>Optimize</Button>
            <Button type="primary" disabled={swapDisabled || progressing || !account} onClick={handleSwap}>Swap</Button>


          </Col>
          <Col span={12}>
            <Switch checkedChildren='advanced mode' unCheckedChildren='normal mode' defaultChecked={false} onChange={handleDetailSwitchChange}/>
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{ width: '100vw', height: '80vh' }}>

                <ReactFlow nodes={nodes} edges={edges} />

            </div>
          </Col>
        </Row>

      </Layout>
    </>
  );
}

export default App;
