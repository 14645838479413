
export type Coin = {
  address: string,
  decimals: number,
  symbol: string,
  name: string,
  logoURL: string,
  projectURL?: string
  extensions? : {
    stableUSD?: boolean,
  }
}

type CoinList = {
  testnet: Coin[]
}

export const coinList: CoinList = {
  testnet: [
    {
      address: '0x1::aptos_coin::AptosCoin',
      decimals: 8,
      symbol: 'APT',
      name: 'Aptos',
      logoURL: 'https://coinlist.animeswap.org/icons/APT.svg',
      projectURL: 'https://aptoslabs.com/',
    },
    {
      address: '0x16fe2df00ea7dde4a63409201f7f4e536bde7bb7335526a35d05111e68aa322c::TestCoinsV1::USDT',
      decimals: 8,
      symbol: 'USDT',
      name: 'Tether USD',
      logoURL: 'https://coinlist.animeswap.org/icons/USDT.webp',
      extensions: {
        stableUSD: true,
      },
    },
    {
      address: '0x16fe2df00ea7dde4a63409201f7f4e536bde7bb7335526a35d05111e68aa322c::TestCoinsV1::BTC',
      decimals: 8,
      symbol: 'BTC',
      name: 'Bitcoin',
      logoURL: 'https://coinlist.animeswap.org/icons/BTC.webp',
    },
    {
      address: '0x16fe2df00ea7dde4a63409201f7f4e536bde7bb7335526a35d05111e68aa322c::AnimeCoin::ANI',
      decimals: 8,
      symbol: 'ANI',
      name: 'AnimeSwap Coin',
      logoURL: 'https://coinlist.animeswap.org/icons/ANI.png',
      projectURL: 'http://animeswap.org/',
    },
    {
      address: '0x2a2ad97dfdbe4e34cdc9321c63592dda455f18bc25c9bb1f28260312159eae27::staked_aptos_coin::StakedAptosCoin',
      decimals: 8,
      symbol: 'tAPT',
      name: 'Tortuga Staked APT',
      logoURL: 'https://coinlist.animeswap.org/icons/tAPT.svg',
    },
    {
      address: '0xc7aef22455c9e3744d98d6dc024d0ed898dc6d307def82abd563653ecbb1e022::koiz_coin::KoizCoin',
      decimals: 6,
      symbol: 'KOIZ',
      name: 'Koiz Coin',
      logoURL: 'https://coinlist.animeswap.org/icons/KOIZ.png',
    },
    {
      address: '0xe4497a32bf4a9fd5601b27661aa0b933a923191bf403bd08669ab2468d43b379::move_coin::MoveCoin',
      decimals: 8,
      symbol: 'MOVE',
      name: 'BlueMove Coin',
      logoURL: 'https://coinlist.animeswap.org/icons/MOVE.svg',
      projectURL: 'https://bluemove.net/',
    },
  ],
}

type CoinPair = [Coin, Coin]
export const coinPairList: CoinPair[] = (
  ()=>{
    const coinPairs: CoinPair[] = []
    for (let i = 0; i < coinList.testnet.length - 1 ; i++) {
      for (let j = i + 1; j < coinList.testnet.length; j++) {

        const coin0 = coinList.testnet[i]
        const coin1 = coinList.testnet[j]

        coinPairs.push([coin0, coin1])
      }
    }
    return coinPairs
  }
)()

