
export interface SwapHost {
  id: number;
  name: string;
  type: string;
  poolAddress: string;
  resourceAccountAddress: string;
  fee: number;
  var_balance_x: string;
  var_balance_y: string;
  color: string;
}

export const swapHostList: SwapHost[] = [
  {
    id: 0,
    name: "PancakeSwap",
    type: "PancakeSwap",
    poolAddress: "c7efb4076dbe143cbcd98cfaaa929ecfc8f299203dfff63b95ccb6bfe19850fa::swap::TokenPairReserve",
    resourceAccountAddress: "c7efb4076dbe143cbcd98cfaaa929ecfc8f299203dfff63b95ccb6bfe19850fa",
    fee: 0.0025,
    var_balance_x: "reserve_x",
    var_balance_y: "reserve_y",
    color: "#0000ff"
  },
  {
    id: 1,
    name: "AnimeSwap",
    type: "AnimeSwap",
    poolAddress: "16fe2df00ea7dde4a63409201f7f4e536bde7bb7335526a35d05111e68aa322c::AnimeSwapPoolV1f1::LiquidityPool",
    resourceAccountAddress: "796900ebe1a1a54ff9e932f19c548f5c1af5c6e7d34965857ac2f7b1d1ab2cbf",
    fee: 0.003,
    var_balance_x: "coin_x_reserve",
    var_balance_y: "coin_y_reserve",
    color: "#ff0000"
  },
  {
    id: 100,
    name: "MilkSwap",
    type: "PancakeSwap",
    poolAddress: "b99770c4b43e7376bdb3edb7f01e329c5d5914c44d34773abbc885f958c844bd::swap::TokenPairReserve",
    resourceAccountAddress: "b99770c4b43e7376bdb3edb7f01e329c5d5914c44d34773abbc885f958c844bd",
    fee: 0.0025,
    var_balance_x: "reserve_x",
    var_balance_y: "reserve_y",
    color: "#00ff00"
  },
  {
    id: 101,
    name: "CupcakeSwap",
    type: "PancakeSwap",
    poolAddress: "c070659ff6ff0c3bb3f6254c2df709fd5a68f90868876271ed2155d2fb2c2d94::swap::TokenPairReserve",
    resourceAccountAddress: "c070659ff6ff0c3bb3f6254c2df709fd5a68f90868876271ed2155d2fb2c2d94",
    fee: 0.0025,
    var_balance_x: "reserve_x",
    var_balance_y: "reserve_y",
    color: "#ffff00"
  },
]